var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.menus, function (item) {
        return [
          item.children.length === 0
            ? _c(
                "el-menu-item",
                {
                  key: item.menuId,
                  attrs: { index: item.menuId },
                  on: {
                    click: function ($event) {
                      return _vm.open(item)
                    },
                  },
                },
                [
                  item.icon && _vm.token
                    ? _c("img", {
                        staticClass: "icon-img",
                        attrs: {
                          src:
                            _vm.iconViewUrl +
                            "?fileId=" +
                            item.icon +
                            "&token=" +
                            _vm.token,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(item.menuName)),
                  ]),
                ]
              )
            : _vm._e(),
          item.children.length !== 0
            ? _c(
                "el-submenu",
                { key: item.menuId, attrs: { index: item.menuId } },
                [
                  _c("template", { slot: "title" }, [
                    item.icon && _vm.token
                      ? _c("img", {
                          staticClass: "icon-img",
                          attrs: {
                            src:
                              _vm.iconViewUrl +
                              "?fileId=" +
                              item.icon +
                              "&token=" +
                              _vm.token,
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(_vm._s(item.menuName)),
                    ]),
                  ]),
                  _vm._l(item.children, function (child) {
                    return [
                      child.children.length === 0
                        ? _c(
                            "el-menu-item",
                            {
                              key: child.menuId,
                              attrs: { index: child.menuId },
                              on: {
                                click: function ($event) {
                                  return _vm.open(child)
                                },
                              },
                            },
                            [
                              child.icon && _vm.token
                                ? _c("img", {
                                    staticClass: "icon-img",
                                    attrs: {
                                      src:
                                        _vm.iconViewUrl +
                                        "?fileId=" +
                                        child.icon +
                                        "&token=" +
                                        _vm.token,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                { attrs: { slot: "title" }, slot: "title" },
                                [_vm._v(_vm._s(child.menuName))]
                              ),
                            ]
                          )
                        : _c("menu-item", {
                            key: child.menuId,
                            attrs: { menus: [child] },
                          }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }